import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from './FadeIn';
import { LoadFadeIn } from './LoadFadeIn';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';

const Home = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);
        
        LoadFadeIn();
        FadeIn();

        // COUNTER NUMBER

        const counters = document.querySelectorAll(".counter");

        counters.forEach((counter) => {
          counter.innerText = "0";
          const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 300;
            if (count < target) {
              counter.innerText = `${Math.ceil(count + increment)}`;
              setTimeout(updateCounter, 1);
            } else counter.innerText = target;
          };
          updateCounter();
        });

        var closeButton = document.getElementById('close-popup');

        closeButton.addEventListener('click', function() {
            var popupBg = document.getElementById('popup-bg');
                popupBg.style.display = 'none';
        });

    }, []);

    return ( 
        <div id="home-page"> 
            <Helmet>
                <title>Oneida County History Center</title>
                <meta name='description' content='Preserving the rich heritage of Oneida County and the greater Mohawk Valley since 1876.' />
            </Helmet>
           <section id="home-hero-section">
                <div id="home-hero-image-body">
                    <img src="assets/home/oneida-history-hero.webp" alt="home-image"/>
                    <div id="home-hero-content">
                        <div class="home-hero-content">
                            <div class="home-hero-content-text">
                                <h1 className='fade-in-up-load'>Oneida County </h1>
                                <h2 className='fade-in-up-load'>History Center</h2>
                                <p className='fade-in-up-load'>
                                Preserving the rich heritage of Oneida County and the greater Mohawk Valley since 1876.                                
                                </p>
                            </div>
                            <a class="fade-in-up-load main-button" href="https://oneidacountyhistory.square.site/donate">Donate</a>
                        </div>
                    </div>
                </div>       
            </section>
            <section id="home-services-section">
                <div className='container'>  
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="card">                              
                                <h2 className='counter fade-in-up' data-target="232000"></h2>
                                <p className='fade-in-up'>
                                Number of residents in Oneida County.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">                                                          
                                <h2 className='counter fade-in-up' data-target="1798"></h2>
                                <p className='fade-in-up'>
                                The year Oneida County was founded by the New York State Legislature.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">                              
                                <h2 className='counter fade-in-up' data-target="42"></h2>
                                <p className='fade-in-up'>
                                Number of languages spoken in Oneida County.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="home-services-content-text">
                        <h1>Our Mission</h1>
                        <p className='fade-in-up'>
                        To protect and preserve the past of Oneida County, as well as central New York, for present and future generations. 
                        The History Center seeks to make this rich heritage readily available to researchers, families, and students enhancing the community's knowledge and appreciation of its history.                        
                        </p>
                    </div>
                    <a className="main-button" href="/about">Learn More
                    </a>                    
                </div>
            </section>
                <div className='save-date container'>
                        <div className='row legends'>
                            <div className='col-lg-6'>
                                <h1>Historical Selfie Scavenger Hunt is August 2-30!</h1>
                                <p className='fade-in-up'>
                                Join the History Center for its eighth annual Historical Selfie Scavenger Hunt. Venture on a county or city-wide scavenger hunt to explore the people, places, and events that shaped Oneida County. Document your travels with a photo or selfie and share it on social media. All ages are encouraged to participate in this free program with prizes from local businesses. Pick up your clue sheets starting August 2.
                                </p>
                                <a id="join-hunt" className="main-button" href="/scavenger-hunt">Join the Hunt!</a>  
                                <a id="hunt-clues" className="main-button" href="assets/2024 History Center Scavenger Hunt (Both Clues).pdf">Clue Sheets & Instructions</a>                                                      
                            </div>
                            <div className='col-lg-6'>
                                <img decoding='async' loading='lazy' alt='Telethon' src="assets/Scavenger_Hunt.png"/>
                            </div>
                        </div>
                    </div>
                    
            <section id="home-why-us-section">
                <div id="home-why-us-image-body">
                    <img decoding='async' loading='lazy'  src="assets/home/oneida-1.jpeg" alt="explore local image"/>
                    <div id="home-why-us-content">
                        <div className="home-why-us-content-text">
                            <div className='section-header'> 
                            Explore Local History
                            </div>
                            <h1>Programs & Events</h1>
                            <p className='fade-in-up'>
                            No matter who you are, there's always something new to learn at  Oneida County History Center. Our monthly programs are a great way to experience the unique history that can only be found in Oneida County.
                            </p>
                        </div> 
                    </div>
                </div>
                <div className='home-why-us-items container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div class="card">
                                <h2 className='fade-in-up'>Eighth Annual Historical Selfie Scavenger Hunt</h2>
                                <p>
                                Friday, Aug 2 - 30
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">
                                <h2 className='fade-in-up'>Battlefield Commemoration</h2>
                                <p>
                                Tuesday, August 6
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div class="card">
                                <h2 className='fade-in-up'>Story Hour at the History Center</h2>
                                <p>
                                Thursday, August 29
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='home-programs-button'>
                        <a className="main-button" href="/programs-and-events">View all programs
                        </a>                   
                    </div>
                </div>
            </section>
            <section id="home-about-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            {/* <iframe width="560" height="400" src="https://www.youtube.com/embed/m3SvbS0aI9I?si=B4Nc8Y9YURlv9xba" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                            <img decoding='async' loading='lazy'  src="assets/visit/exhibits/GlobeHorizontalAdvert.webp" alt="Saturday Globe Cover"/>
                        </div>
                        <div className='col-lg-6'>
                            <div class="home-about-content">
                                <div class="home-about-content-text">
                                    <div className='section-header'> 
                                        Featured Exhibit
                                    </div>
                                    <h1 className='fade-in-up'>Saturday Globe: National News in Color</h1>
                                    <p className='fade-in-up'>
                                    The Saturday Globe redefined the traditional newspaper and expanded regional journalism through innovative color printing and distribution methods.
                                    </p>
                                    <p className='fade-in-up'>
                                    Printed locally in Utica from 1881 to 1924, the GLOBE shipped to 40 different editions to ...
                                    </p>
                                </div>
                                <a target="_blank" className="main-button" href="/exhibits">Read More 
                                </a>                   
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-shop-section">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div class="container home-shop-content">
                                <div class="home-shop-content-text">
                                    <h1 className='fade-in-up'>Shop Local History</h1>
                                    <p className='fade-in-up'>
                                    Visit the History Center's e-Bookstore for a great selection of local history books and merchandise. 
                                    </p>
                                    <p className='fade-in-up'>
                                    Our entire inventory is available to purchase online with curbside pickup available! 
                                    </p>
                                </div>
                                <a target="_blank" className="main-button-white" href="https://oneidacountyhistory.square.site/">Shop Now
                                </a>                   
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <img decoding='async' loading='lazy'  src="assets/home/bookstore_2024.jpg" alt="Shop"/>
                        </div>
                    </div>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News
                        </div>
                        <h1>Latest Events</h1>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="/assets/about/news/utica_boo.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center Seeks Auditions for Utica Boo!</h4>
                                    <p class="card-text fade-in-up">
                                    Oneida County History Center is seeking actors for its upcoming stage production of “Utica Boo!” A haunted whodunnit featuring ghosts from historical Utica’s past. Local author, and paranormal investigator, Dennis Webster, wrote this fun and interactive work of historical fiction as a fundraiser for the Oneida County History Center ....
                                    </p>
                                    <a href="/news-utica-boo" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>  
                        <div className="col-lg-6">
                            <div class="card news-card">
                                <img decoding='async' loading='lazy'  class="card-img-top" src="assets/support/Ellen_Luley.png" alt='News'/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Volunteer of the Year: Ellen Luley</h4>
                                    <p class="card-text fade-in-up">
                                    She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year. Ellen was honored with this award during the annual membership meeting on December 12, 2023. 
                                    </p>
                                    <a href="/news" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </section>
            <div id="popup-bg">
                <div id="popup" method="post" netlify-honeypot="bot-field" data-netlify="true">
                    <div className='popup-body'>
                        <div id="popup-right" >
                            <div className='popup-question'>
                                <button type="button" id="close-popup">X</button>
                            </div>
                            <img src='/assets/independenceday.png'/>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default Home;
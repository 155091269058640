import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import { FadeIn } from '../FadeIn';
import ReactGA from 'react-ga';

const News = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);

        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="news-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/about/news/TEMP-news-header.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Latest Events & News</h1>
                    <h4 className='fade-in-up-load'>
                    Learn more about all the latest events and news from us at Oneida County History Center.
                    </h4>
                </div>
            </section>
            <section className='news-section'>
                <div className='news-body'>
                    <div class="card news-card top-news">
                        <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/about/news/ochctelethon23.jpg" />
                        <div class="card-body">
                            <h4 class="card-title fade-in-up">Save the Date! History Center Telethon is May 15!</h4>
                            <p class="card-text fade-in-up">
                            Oneida County History Center is excited to announce its 19th Annual Telethon presented by Bank of Utica! This signature fundraising event will be held on Wednesday, May 15, 2024, from 5 - 8 p.m. Tune in to the live broadcast on WUTR-TV or watch the live stream on CNYHomepage.com... 
                            </p>
                            <a href="/news-telethon-2024" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                        </div>
                    </div>
                </div>
                <div className="row container"> 
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="/assets/about/news/utica_boo.jpg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center Seeks Auditions for Utica Boo!</h4>
                                    <p class="card-text fade-in-up">
                                    Oneida County History Center is seeking actors for its upcoming stage production of “Utica Boo!” A haunted whodunnit featuring ghosts from historical Utica’s past. Local author, and paranormal investigator, Dennis Webster, wrote this fun and interactive ....
                                    </p>
                                    <a href="/news-utica-boo" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/about/news/Five_Families_Band_Photo.jpeg" />
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Monk Rowe Awarded Grant for History Concerts</h4>
                                    <p class="card-text fade-in-up">
                                    Monk Rowe & the Five Families Ensemble have partnered with Oneida County History Center to present two concerts in 2024. The Ensemble offers a unique instrumentation comprised of the four instrumental families (strings, woodwinds, brass, and percussion)... 
                                    </p>
                                    <a href="/news-five-families" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row container">
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/support/Ellen_Luley.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Volunteer of the Year: Ellen Luley</h4>
                                    <p class="card-text fade-in-up">
                                    She “goes the extra mile” and has “a great work ethic and enthusiasm.” She “provides great customer service and engages every visitor.” This is how fellow volunteers and staff describe Ellen Luley, History Center volunteer of the year. Ellen was honored with this award during... 
                                    </p>
                                    <a href="/news-ellen-luley" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-6">
                        <div className='news-body'>
                            <div class="card news-card">
                                <img decoding='async' loading='lazy' alt='news event' class="card-img-top" src="assets/about/news/HallofFame.JPG"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">2023 Historical Hall of Fame!</h4>
                                    <p class="card-text fade-in-up">
                                    The Oneida County Historical Hall of Fame has named four individuals to its 2023 inductee class. The inductees will be honored during the annual Historical Hall of Fame and Living Legends Awards Celebration to be held at the Irish Cultural Center, Utica                               
                                    </p>
                                    <a href="/news-3" class="card-link">Learn More <i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div> 
                    </div>   
                    
                </div>
            </section>
        </div>
    );
}

export default News;
import React from 'react';
import { useEffect } from 'react';
import { LoadFadeIn } from '../LoadFadeIn';
import { FadeIn } from '../FadeIn';
import ReactGA from 'react-ga';


const VisitUs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

        var heroVideo = document.getElementById("CNY_Drone_Video");
        
        heroVideo.oncanplaythrough = function() {
            heroVideo.muted = true;
            heroVideo.setAttribute("playsinline", "");
            heroVideo.play();
        }

    }, []);

    return (
        <div id="visit-us-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <video  alt='Header' className='page-header-img' id="CNY_Drone_Video" playsinline autoplay muted loop poster="assets/TEMP-visit-header.png">
                        <source src="assets/visit/visit-us/visit-building.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Header" className="mobile_img page-header-img" src="assets/TEMP-visit-header.png"/>

                </div>   
                <div className='page-header-title'>
                    <h1 className='fade-in-up-load'>Visit Us</h1>
                    <h4 className='fade-in-up-load'>Discover central New York and Oneida County history with a visit to Oneida County History Center.</h4>
                </div>             
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2 className='fade-in-up'>Visiting the History Center</h2>
                    <p className='fade-in-up'>The History Center is open five days a week Tuesday through Saturday from 10:00 a.m. 
                        to 4:00 p.m.and is open to all visitors. Experience history in our 5,000 square-foot 
                        gallery with multiple exhibits running concurrently, enjoy weekly programs and special 
                        events, browse our bookstore featuring Oneida County history books, authors, and 
                        merchandise, or dive into your own research at the Tharratt Gilbert Best Research 
                        Library.
                    </p>
                    <p className='fade-in-up'>
                    Exhibits and most programs are pay what you wish admission, with a recommended donation of $5 per person. The research library is open exclusively by appointment only.
                    </p>
                    <p className='fade-in-up'>
                    The History Center is closed on Mondays and Sundays and all national holidays. Summer hours may differ.
                    </p>
                    <br/>

                    <h2 className='fade-in-up'>Hours & Admission</h2>
                    <h5 className='fade-in-up'>Exhibits & Bookstore:</h5>
                    <p className='fade-in-up'>Pay what you wish admission ($5 suggested donation); free for members
                    </p>
                    <p className='fade-in-up'>
                    Monday: 10 a.m. to 4 p.m.                       
                    </p>
                    <p className='fade-in-up'>
                    Tuesday: 10 a.m. to 4 p.m.                    
                    </p>
                    <p className='fade-in-up'>
                    Wednesday: 10 a.m. to 4 p.m.
                    </p>
                    <p className='fade-in-up'>
                    Thursday: 10 a.m. to 4 p.m.
                    </p>
                    <p className='fade-in-up'>
                    Friday: 10 a.m. to 4 p.m.
                    </p>
                    <p className='fade-in-up'>
                    Saturday: Closed (Open between Labor Day to Memorial Day)
                    </p>
                    <br/>
                    <h5 className='fade-in-up'>Research Library:</h5>
                    <p className='fade-in-up'>$10 per visit; free for members</p>
                    <p className='fade-in-up'>Access to the research library is <strong><i>by appointment only</i></strong>. 
                        To book an appointment, call 315-735-3642 or contact research@oneidacountyhistory.org. 
                        Please call if you wish to visit in the next 24-48 hours. Children under 12 are not permitted 
                        in the research library and children under 16 must be accompanied by an adult.
                    </p>
                    <p className='fade-in-up'>
                    The History Center is not a public library. Admission and fees associated with your visit subsidize the cost of 
                    preservation and access to its collections. The History Center is a 501 c-3 non-profit institution and does note 
                    receive annual city, county, or state funding. It is supported by the generosity of its visitors, members, 
                    private donors, and the community.
                    </p>


                    <h2 className='fade-in-up'>Our Location</h2>
                    <p className='fade-in-up'>1608 Genesee Street (corner of Avery Place)</p>
                    <p className='fade-in-up'>Utica, NY 13502</p>
                    <p className='fade-in-up'>The research library is located on the Lower Level of the building. We recommend using the 
                        Avery Street entrance to access the library and for visitors with limited mobility. 
                        Exhibits, bookstore, programs, and administrative offices are located on the Main Level 
                        of the building.
                    </p>
                    <div className='google-map'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11654.480941238513!2d-75.2523385!3d43.0914788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9412dc1632acf%3A0xcaba271eb9a1d0b0!2sOneida%20County%20History%20Center!5e0!3m2!1sen!2sus!4v1694956871181!5m2!1sen!2sus" width="1200" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                
                    </div>

                    <br/>
                    <h2 className='fade-in-up'>Parking & Accessibility</h2>
                    <p className='fade-in-up'>The History Center provides free parking in the lot located behind its building. Street parking is also 
                        available on all side streets and Genesee Street (up to 2 hours).
                    </p>
                    <p className='fade-in-up'>Our wheelchair-accessible entrance is located on Avery Place. Use the parking lot behind the building 
                        and follow the sidewalk ramp to the door. Use the elevator (lift) just inside the door to take you 
                        to the lower lever for library access and the main level for all other purposes. Our wheelchair-accessible 
                        restroom is also located at this entrance.
                    </p>
                </div>
            </section>
        </div>
    );
}

export default VisitUs;
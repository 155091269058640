import React from 'react';
import { useEffect } from 'react';
import { FadeIn } from '../FadeIn';
import { LoadFadeIn } from '../LoadFadeIn';
import ReactGA from 'react-ga';



const Programs = () => {

    useEffect(() => {

        ReactGA.pageview(window.location.pathname + window.location.search);


        LoadFadeIn();
        FadeIn();

    }, []);

    return (
        <div id="programs-page">
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <img className='page-header-img' src='assets/visit/programs/TEMP-programs.webp' alt='Header'></img>
                </div>                
                <div className='page-header-title'>
                    <h1 className="fade-in-up-load">Programs & Events</h1>
                    <h4 className="fade-in-up-load">
                    No matter who you are, there's always something new to learn at Oneida County History Center. 
                    Our monthly programs are a great way to experience the unique history that can only be found 
                    in Oneida County.
                    </h4>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Upcoming Events</h2>
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/openhouse_july.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Proctor Memorial Building Open House</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, July 13, 2024
                                    </p>
                                    <a href="/openhouse-july" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/storyhour_july.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Story Hour at the History Center</h4>
                                    <p class="card-text fade-in-up">
                                    Thursday, July 25, 2024
                                    </p>
                                    <a href="/story-hour-july" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/scavenger_hunt.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Eighth Annual Historical Selfie Scavenger Hunt</h4>
                                    <p class="card-text fade-in-up">
                                    Friday, August 2 - 30 2024
                                    </p>
                                    <a href="/scavenger-hunt" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className='row'>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/oriskany_battle.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Oriskany <br/>Battlefield Commemoration</h4>
                                    <p class="card-text fade-in-up">
                                    Tuesday, August 6, 2024
                                    </p>
                                    <a href="/oriskany-battle" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                        <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/behindscenesjune.png"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">After Hours Behind the Scenes Tour</h4>
                                    <p class="card-text fade-in-up">
                                    Wed, August 21, 2024
                                    </p>
                                    <a href="/behind-the-scenes-august" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/storyhour_august.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Story Hour at the History Center</h4>
                                    <p class="card-text fade-in-up">
                                    Thursday, August 29, 2024
                                    </p>
                                    <a href="/storyhour-august" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section container'>
                <div className='container calendar'>
                    <div className='row top'>
                        <div className='col-lg-8'>
                            <h2>View the <span>calendar</span> for more upcoming events and programs!</h2>
                        </div>
                        <div className='col-lg-4'>
                        <a className="video-button" 
                                href="/events"
                                aria-label='calendar'
                                target='_blank'
                            >OCHC Calendar</a>                          
                        </div>
                    </div>
                </div>
            </section>
            <section className='visit-us-section'>
                <div className='container'>
                    <h2>Past Events</h2>
                    <div className='row'>

                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/uticapotteryjune.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Utica Potteries (*Other than White’s Pottery)</h4>
                                    <p class="card-text fade-in-up">
                                    Wednesday, June 12, 2024
                                    </p>
                                    <a href="/utica-pottery-june" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/openhouseproctor.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">Open House at the Proctor Memorial Building</h4>
                                    <p class="card-text fade-in-up">
                                    Saturday, June 15, 2024
                                    </p>
                                    <a href="/open-house-proctor" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div class="card programs-card">
                                <img decoding='async' loading='lazy'  alt='upcoming program' class="card-img-top" src="assets/visit/programs/market.jpg"/>
                                <div class="card-body">
                                    <h4 class="card-title fade-in-up">History Center Goes to the Market                                    </h4>
                                    <p class="card-text fade-in-up">
                                    Wed, July 10, 2024 (and more!)
                                    </p>
                                    <a href="/market" className="main-button expand-button">Read More 
                                    </a>  
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </section>
            <section className='programs-vids-section'>
                <div className='container'>
                    <div className='row top'>
                        <div className='col-lg-6'>
                            <h2>Learn More on Our Youtube Channel!</h2>
                        </div>
                        <div className='col-lg-6'>
                            <a className="video-button" 
                                href="https://www.youtube.com/channel/UCxL6DtlD7yI6ioAsD7z9Oxg"
                                aria-label='Newsletter'
                                target='_blank'
                            >OCHC Youtube</a>                        
                        </div>
                    </div>
                    <div className="row"> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/rdlMuOtOMmI?si=IXvgAIKAJMp3rl0M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>    
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/T2pI7lArnak?si=qSFgQjH0ki4NeiMB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                        
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8KSf7v-GqDc?si=h4axbzZ7BCaLSqMO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div> 
                        <div className="video-body col-lg-3">
                            <iframe src="https://www.youtube.com/embed/8H29_BOnHY4?si=5zBRdYM2petEqePP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Programs;
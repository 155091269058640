import React from 'react';
import { useEffect } from 'react';

const Footer = () => {

    return (
        <div>
            <section className='footer-top'>
                <div className='row'>
                    <div className='col-lg-5'>
                        <h4>Stay informed by subscribing to our E-newsletter or by following us:</h4>
                    </div>
                    <div className='col'>
                        <a className="main-button" 
                            href="https://gem.godaddy.com/p/db6e571?pact=9898-176015742-14580543343-2ff9ace1f9399edbc77a9105805a80bdabb75c48"
                            aria-label='Newsletter'
                            target='_blank'
                        >Newsletter</a>
                    </div>
                    <div className='col links-col'>
                        <ul>
                            <a className="" 
                                href="https://www.youtube.com/@oneidacountyhistorycenter"
                                target="_blank"
                                rel="noreferrer"
                                aria-label='Youtube'
                            >
                                <i className="fa fa-youtube fa-1x"></i>
                            </a>
                        </ul>
                        <ul>
                            <a className="" 
                                href="https://www.facebook.com/OneidaCountyHistory"
                                target="_blank"
                                rel="noreferrer"
                                aria-label='Facebook'
                            >
                                <i className="fa fa-facebook fa-1x"></i>
                            </a>
                        </ul>
                        <ul>
                            <a className="" 
                                href="https://www.instagram.com/ochc_localhistory/"
                                target="_blank"
                                rel="noreferrer"
                                aria-label='Instagram'
                            >
                                <i className="fa fa-instagram fa-1x"></i>
                            </a>
                        </ul>
                        </div>
                    </div>
            </section>
            <footer>
                <section id="footer-section">
                    <div className='footer-body'>
                        <div className='footer'>
                            <div className='footer-txt'>
                                <ul><a href="https://www.google.com/maps/place/Oneida+County+History+Center/@43.0914788,-75.2523385,15z/data=!4m6!3m5!1s0x89d9412dc1632acf:0xcaba271eb9a1d0b0!8m2!3d43.0914788!4d-75.2523385!16s%2Fg%2F1thtql52?entry=ttu">1608 Genesee Street, Utica, New York 13502-5425</a></ul>
                                <ul><a href="tel:315-735-3642">315-735-3642</a></ul>
                                <ul><a href="mailto:ochc@oneidacountyhistory.org">ochc@oneidacountyhistory.org</a></ul>
                                <ul><a href="mailto:research@oneidacountyhistory.org">research@oneidacountyhistory.org</a></ul>
                              </div>
                        </div>
                        <div className='row'>
                            <div className='copyright-footer col-lg-6'>
                                <br/>
                                <p className='copyright-txt'>
                                © 2023-2024 Oneida County History Center. All Rights Reserved.
                                </p>
                            </div>
                            <div className='copyright-footer col-lg-6' id='right-side'>
                                <br/>
                                <p className='copyright-txt'>Website By:
                                <span><a href="http://unawebsolutions.com"> Una Web Solutions</a></span>
                                </p>
                            </div>
                        </div>
                </div>
            </section>
        </footer>        
    </div>

    );
}

export default Footer;